import React, {PropsWithChildren} from "react";
import {
  Block,
  BlockPageContainer, getClassName,
  JSONViewer,
  KirbyProps,
  parseCss,
  useSection,
} from "@gk-lab/kirbyreact";
import { CustomBlock } from "../types/Block";
import { Box } from "@mui/material";

interface Props extends KirbyProps {
  data: BlockPageContainer;
  getBlocks(
    b: Block | CustomBlock,
    idx: number,
    scope?: string,
  ): React.JSX.Element | null;
}

export default function PageContainer(props: Props): React.JSX.Element {
  const { data, getBlocks } = props;
  const { page, css } = data;
  const sectionData = useSection(page);

  return sectionData && sectionData.blocks ? (
    <Box
      id={props.data.id}
      className={getClassName(data, 'page-container', page)}
      sx={{ ...(css && parseCss(css)) }}
    >
      {sectionData.blocks.map((b, idx) => getBlocks(b, idx))}
    </Box>
  ) : (
    <JSONViewer data={props.data} name={"Pagecontainer"} />
  );
}
