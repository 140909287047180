import {createTheme, darken} from "@mui/material";
import { CustomScope } from "./types/Scope";
import {h1, h2, h3, h4, h5, h6} from "./styles/headlines";
import { p } from "./styles/text";
import {defaultStyles} from "./styles/uistyles";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    main: true;
    [CustomScope.FOOTER]: true;
    [CustomScope.HEADER]: true;
    [CustomScope.STAGE]: true;
    [CustomScope.CARD]: true;
  }
}

declare module "@mui/material/Toolbar" {
  interface ToolbarPropsVariantOverrides {
    transparent: true;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    [CustomScope.FOOTER]: true;
    [CustomScope.HEADER]: true;
    [CustomScope.STAGE]: true;
    [CustomScope.MAIN]: true;
    [CustomScope.NAVIGATION]: true;
  }
}

export const appWidth = 1920
export const appWidthPx = `${appWidth}px`

const font = "MuseoSans";
const fontFamily = [font, '"Helvetica Neue"', "Arial", "sans-serif"].join(",");

const baseTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export const headerHeight = {xs: "64px", md: "124px"}

export const white = "#fff";
export const black = "#000";
export const dark = "#333";

export const primary = "#c12525";
export const primaryDark = darken(primary, 0.2);

export const secondary = "#27272b";
export const secondaryDark = "#27272b";
export const secondaryLight = "#dddddd";

export const accent = primary;
export const accentDark = primaryDark;

export const footerBackground = secondaryDark;


export const lightGrey = "#dddddd";

export const defaultPadding = { xs: "1rem", sm: "4.5rem" };

export const slideshowButtonSx = {
  background: accent,
  svg: { color: white },
  ":hover": {
    background: white,
    svg: { color: accent },
  },
  "&:active, &:focus, &.active, &.focus ": {
    background: accent,
    svg: { color: white },
  },
  display: "none",
  [baseTheme.breakpoints.up("md")]: {
    display: "inline-flex",
  },
};


export const theme = createTheme({
  breakpoints: baseTheme.breakpoints,
  spacing: 4,
  palette: {
    common: {
      black: "#000",
      white: white,
    },
    primary: {
      main: primary, // color-accent-primary-2
      dark: primaryDark, // color-accent-primary-2-dark
      light: "#d5d5d5", // color-accent-primary-3
    },
    secondary: {
      main: secondary, // color-accent-primary-1
      dark: secondaryDark, // color-accent-primary-2-dark
    },
    error: {
      main: primary,
    },
    warning: {
      main: "#FBC91D", // color-status-warning
      dark: "#AD1035", // color-secundary-1
      light: "rgba(251,201,29,0.15)", // color-status-warning 15 %
    },
    info: {
      main: primary, // color-status-warning-high_prio
      light: "rgba(215,15,55,0.07)", // color-status-warning-high_prio 7 %
    },
    success: {
      main: "#3C992E", // color-status-success
      light: "rgba(10,153,46,0.1)", // color-status-success 10 %
    },
  },
  typography: {
    fontFamily: fontFamily,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ...defaultStyles(baseTheme.breakpoints),
        ".SimpleCard": {
          ".card-center": {
            width: "100%"
          }
        },
        ".button-container": {
          "&.footer": {
            width: "unset"
          }
        }
      },
    },
    MuiTypography: {
      defaultProps: {
        fontFamily: fontFamily,
        variantMapping: {
          h1: "h1",
          h2: "h2",
          h3: "h3",
          h4: "h4",
          h5: "h5",
          h6: "h6",
          subtitle1: "h3",
          subtitle2: "h3",
          body1: "p",
          body2: "p",
        },
      },
      styleOverrides: {
        root: {
          ...p(baseTheme.breakpoints),
          "&.left": {
            textAlign: "left",
          },
          "&.center": {
            textAlign: "center",
          },
          "&.right": {
            textAlign: "right",
          },
          "&.primary": {
            color: secondaryDark,
          },
          "&.secondary": {
            color: white,
          },
          "&.accent": {
            color: white,
          },

          "h1": h1(baseTheme.breakpoints),
          "h2": h2(baseTheme.breakpoints),
          "h3": h3(baseTheme.breakpoints),
          "h4": h4(baseTheme.breakpoints),
          "h5": h5(baseTheme.breakpoints),
          "h6": h6(baseTheme.breakpoints),
          p: {
            minHeight: "1rem",
          },
          "& a": {
            color: primary,
            whiteSpace: "nowrap",
          },
          "& a:hover": {
            color: primaryDark,
          },
          "&.copyright, &.disclaimer": {
            fontSize: "1rem",
          },
        },
        /*body1: {
          fontWeight: 300,
          "& p, a": {
            // lineHeight: 1.2,
          },
          a: {
            color: primary,
            textDecoration: "underline",
            cursor: "pointer",
          },
          "a:hover": {
            color: primaryDark,
            textDecoration: "underline",
          },
          "&:not(.panel)": {
            // maxWidth: "calc(100% - 2rem)",
          },
          // Inline Styles
          "& p, h2, h3, h4, h5, h6": {
            paddingLeft: "0",
            paddingRight: "0",
            wordWrap: "break-word",
          },
          "&.center": {
            alignSelf: "center",
          },
          "&.footer": {
            "> p": {
              color: white,
              fontSize: "1rem"
            },
            "> p > a": {
              color: white,
              textDecoration: "none",
              "&:hover,&:focus": {
                color: primary,
              },
            },
          },
          [baseTheme.breakpoints.up("sm")]: {
            lineHeight: 1.5,
            "& p, a": {
              lineHeight: 1.5,
            },
          },
        },*/
        /*body2: {
          fontWeight: 300,
          fontSize: "1rem",
          "& p, a": {
            // marginBlockEnd: "0",
            wordWrap: "break-word",
            fontSize: "inherit"
          },
          a: {
            color: primary,
            textDecoration: "underline",
            cursor: "pointer",
          },
          "&.copyright, &.disclaimer": {
            fontSize: "1rem",
          },
        },*/
        h1: h1(baseTheme.breakpoints),
        h2: h2(baseTheme.breakpoints),
        h3: h3(baseTheme.breakpoints),
        h4: h4(baseTheme.breakpoints),
        h5: h5(baseTheme.breakpoints),
        h6: h5(baseTheme.breakpoints),
      },
      variants: [
        {
          props: { variant: CustomScope.FOOTER },
          style: {
            color: white,
            paddingBottom: "0",
            textAlign: "center",
            "& a, p": {
              color: white,
              textAlign: "center",
              marginBlockEnd: "0 !important",
              textDecoration: "none",
            },
            "& a:hover": {
              // fontWeight: "bold",
              color: primary,
            },
            [baseTheme.breakpoints.up("lg")]: {},
          },
        }
      ]
    },
    MuiButton: {
      styleOverrides: {
        root: {
          pointerEvents: "auto",
          borderRadius: 0,
          borderWidth: 1,
          borderColor: 'transparent',
          padding: "0.75rem 0.75rem",
          margin: 0,
          lineHeight: 1.25,
          letterSpacing: 1.25,
          textAlign: "center",
          width: "fit-content",
          fontSize: "1rem",
          textTransform: "uppercase",
          "&:hover, &:focus, &:active": {
            backgroundColor: primaryDark,
            color: white,
          },
          [baseTheme.breakpoints.up("md")]: {
            //fontSize: "1.5rem",
            //padding: "1.625rem 2rem",
          },
          [baseTheme.breakpoints.up("lg")]: {
            fontSize: "1.625rem",
            padding: "1.625rem 2rem",
          },
          "& svg": {
            background: "transparent !important",
          },
          "&.primary": {
            backgroundColor: primary,
            color: white,
            "&:hover, &:active": {
              backgroundColor: "transparent",
              color: primary,
            },
            "&:focus": {
              background: primary,
              color: white,
              textDecoration: "none"
            },
          },
          "&.secondary": {
            color: black,
            backgroundColor: "transparent",
            "&:hover, &:active": {
              backgroundColor: "transparent",
              color: primary,
            },
            "&:focus": {
              backgroundColor: "transparent",
              color: black,
              textDecoration: "none"
            },
          },
          "&.footer": {
            textTransform: "none",
            background: "transparent",
            color: white,
            width: "unset",
            fontSize: "1rem",
            [baseTheme.breakpoints.up("md")]: {
              fontSize: "1.25rem",
            },
            [baseTheme.breakpoints.up("lg")]: {
              fontSize: "1.25rem",
            },
            "&:hover, &:focus, &:active": {
              color: white,
              textDecoration: "underline",
              background: "transparent",
              borderWidth: 0
            },
          },
          "&.main-navigation": {
            fontSize: "1.25rem",
            padding: "1rem 1.5rem",
            fontWeight: 700,
            "&.primary": {
              fontSize: "1.5rem",
            },
            "&.secondary": {

            },
            [baseTheme.breakpoints.up("sm")]: {

            },
            [baseTheme.breakpoints.up("md")]: {

            },
            [baseTheme.breakpoints.up("lg")]: {
              padding: "1.625rem 2rem",
            },
          },
          "&.download": {
            textTransform: "none !important",
            textAlign: "left",
            background: 'none',
            alignItems: "flex-start",
            padding: "0.125rem 1rem",
            "img": {
              margin: "-4px 0.25rem 0 0.25rem"
            },
            "span": {
              color: secondary,
              lineHeight: 1,
              fontSize: "inherit"
            },
            "&:hover, &:focus, &:active": {
              background: 'none',
              "span": {
                color: primary,
                textDecoration: "underline"
              }
            },
            [baseTheme.breakpoints.up("md")]: {
              fontSize: "1.25rem",
            },
            [baseTheme.breakpoints.up("lg")]: {

            },
          },
        },
      },
      variants: [
        /*{
          props: { variant: "contained" },
          style: {
            color: white,
            alignSelf: "center",
            textAlign: "center",
            lineHeight: 0.95,
            backgroundColor: `${primary} !important`,
            "&.active": {
              color: white,
              backgroundColor: primary,
            },
            "&:hover,&:focus": {
              color: white,
              backgroundColor: `${primaryDark} !important`,
            },
          },
        },*/
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          backgroundColor: accent,
          borderRadius: 0,
          color: white,
          "&.active": {
            color: white,
            backgroundColor: primary,
          },
          "&:hover,&:focus": {
            color: white,
            backgroundColor: primaryDark,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          // backgroundColor: accent,
        },
        paperFullScreen: {
          backgroundColor: accent,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: "0 !important",
          paddingRight: "0 !important",
          ".imagelist": {
            ".container-elements": {
              marginTop: "-1px",
              marginLeft: "-1px",
            },
            ".image": {
              outline: "1px solid #707070",
              marginTop: "1px",
              marginLeft: "1px",
            },
          },
          ".infoimage": {
            "h2,h3,h4": {
              position: "relative",
              marginTop: "1rem",
            },
          },
          ".footer-meta-container": {
            backgroundColor: white,
            padding: "1rem",
            flexDirection: "column",
            justifyContent: "column",
            [baseTheme.breakpoints.up("sm")]: {
              padding: "1rem 6rem 2rem 6rem",
              flexDirection: "row",
              justifyContent: "space-between",
            },
          },
        },
        maxWidthXl: {
          paddingLeft: "0",
          paddingRight: "0",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
        },
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          "label > span": {
            fontSize: "1rem",
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "4rem",
          backgroundColor: white,
          color: dark,
          paddingLeft: "0 !important",
          paddingRight: "0 !important",
        },
      },
      variants: [
        {
          props: { variant: "transparent" },
          style: {
            background: "transparent",
          },
        },
      ],
    },
    MuiLink: {
      defaultProps: {
        fontFamily: fontFamily,
      },
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
          lineHeight: 1.2,
          cursor: "pointer",
          "&.primary": {
            textDecorationColor: secondary,
            "&:hover,&:focus": {
              color: primary,
              textDecorationColor: primary,
            },
          },
          "&.secondary, &.meta-navigation-link": {
            color: secondary,
            textDecorationColor: secondary,
            "&:hover,&:focus": {
              color: primary,
              textDecorationColor: primary,
            },
          },
          "&.footer": {
            textDecoration: "none",
            lineHeight: 1.5,
            "&:hover,&:focus": {},
          },
          "&.footer-meta-navigation": {
            fontSize: "1rem",
            "&:not(:last-child):after": {
              content: '"|"',
              margin: "0 0 0 0.5rem",
              textDecoration: "none",
            },
            "&:hover,&:focus": {
              "&::after": {
                color: secondary,
              },
            },
          },
          "&.meta-navigation-link": {
            color: white,
            textDecoration: "none",
            "&:not(:last-child):after": {
              content: '"|"',
              margin: "0 0.5rem",
              textDecoration: "none",
            },
            "&:hover,&:focus": {
              "&::after": {
                color: white,
              },
            },
          },
          "&.accent": {
            color: white,
            textDecorationColor: white,
            "&:hover,&:focus": {
              color: primary,
              textDecorationColor: primary,
            },
          },
          "&.footer.secondary": {
            color: white,
            textDecorationColor: white,
            "&:hover,&:focus": {
              color: primary,
              textDecorationColor: primary,
            },
          },
          "&.center": {
            margin: "0 auto",
          },
          [baseTheme.breakpoints.up("lg")]: {
            lineHeight: 1.5,
          },
        },
      },
      variants: [
        {
          props: { variant: CustomScope.MAIN },
          style: {
            color: white,
            textTransform: "uppercase",
            // background: "transparent",
            textDecoration: "none",
            textAlign: "right",
            fontWeight: 500,
            fontSize: "3rem",
            marginBottom: 0,
            lineHeight: 1,
            paddingTop: 0,
          },
        },
        {
          props: { variant: CustomScope.FOOTER },
          style: {
            color: white,
            textDecorationColor: white,
            textAlign: "center",
            marginBlockEnd: "0 !important",
            ":hover": {
              // fontWeight: "bold",
              color: primary,
              // textDecorationColor: white,
            },
            [baseTheme.breakpoints.up("lg")]: {
              // fontSize: "1rem",
            },
          },
        },
        {
          props: { variant: CustomScope.NAVIGATION },
          style: {
            color: white,
            textDecoration: "none",
            textTransform: "uppercase",
            textAlign: "center",
            fontSize: "1.25rem",
            ":hover": {
              color: primary,
            },
            [baseTheme.breakpoints.up("sm")]: {
              fontSize: "2rem",
            },
          },
        },
      ],
    },
    MuiDivider: {
      styleOverrides: {
        root: {},
        vertical: {
          borderColor: "rgba(255,255,255,0.3)",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          boxShadow: "none",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "1rem 1rem",
          ".card-title": {
            // textAlign: "center",
            color: primary,
            fontSize: "1.25rem",
            fontWeight: 700,
            marginBottom: "0.5rem",
            lineHeight: 1,
            textTransform: "upperCase",
            padding: 0,
            //maxHeight: "3.5rem",
            wordWrap: "break-word",
            textAlign: "left",
            [baseTheme.breakpoints.up("md")]: {
              fontSize: "1.75rem",
            },
          },
          ".card-subtitle": {
            fontSize: "1rem",
            marginBottom: "0.25rem",
            lineHeight: 1,
            padding: 0,
            fontWeight: 500,
            textAlign: "left",
            color: primary,
            [baseTheme.breakpoints.up("md")]: {
              fontSize: "1.5rem",
            },
          },
          ".card-copy": {
            // textAlign: "center",
            fontSize: "1.25rem",
            margin: "0.5rem 0 0.25rem 0",
            padding: 0,
            "> p": {
              margin: 0,
            },
          },
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          height: "100%",
          position: "absolute",
          top: 0,
          bottom: 0,
          right: "1rem",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});
