import {Breakpoints, CSSObject} from "@mui/material";
import {primary, primaryDark, secondaryDark} from "../Theme";

export const p = (breakpoints:Breakpoints):CSSObject => ({
  fontSize: "1rem",
  fontWeight: 300,
  lineHeight: 1.2,
  margin: 0,
  position: "relative",
  color: secondaryDark,
  [breakpoints.up("md")]: {
    fontSize: "1.625rem",
  },
  a: {
    color: primary,
    textDecoration: "underline",
    cursor: "pointer",
  },
  "a:hover": {
    color: primaryDark,
    textDecoration: "underline",
  },
  "& p, a": {
    fontSize: "inherit",
    fontWeight: "inherit",
    margin: "inherit",
    marginBottom: "1rem"
  },
})
