import IconButton from '@mui/material/IconButton';
import React, {FC, ReactElement} from 'react';
import {accent, white} from "../Theme";
import MenuIcon from "@mui/icons-material/Menu";
import {alpha, styled} from "@mui/material/styles";
import {Box, Menu, MenuItem, MenuProps, Stack} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {BlockButton, BlockLink, KirbyProps} from "@gk-lab/kirbyreact";
import CustomButton from "./CustomButton";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    zIndex: 4000,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

interface NavigationProps extends KirbyProps {
  mainItems?: (BlockLink | BlockButton)[];
  metaItems?: (BlockLink | BlockButton)[];
}

const Navigation: FC<NavigationProps> = ({mainItems, sx}): ReactElement => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event?: React.MouseEvent<HTMLElement>) => {
    if(event) setAnchorEl(event.currentTarget);
  };
  const handleItemClick = (item:BlockLink | BlockButton) => {
    if(item.href) {
      window.open(item.href)
    } else {
      const url = `${item.to ?? '#'}${item.anchor ?? ''}`
      navigate(url, {replace: true})
    }
    handleClose()
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

    return (
      <>
      <Box sx={{display: {xs: "block", md: "none"}, ...sx}}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={(event) => open ? handleClose() : handleClick(event)}
          edge="end"
          sx={{
            width: "3rem",
            height: "3rem",
            mr: 0,
            backgroundColor: white,
            color: accent,
            borderRadius: 0,
            "&:hover,&:focus,&:active": {
              backgroundColor: accent,
              color: white,
            },
          }}
        >
          <MenuIcon />
        </IconButton>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {mainItems?.map((item) => {
            return (
              <MenuItem onClick={(evt) => handleItemClick(item)} disableRipple>
                {item.text}
              </MenuItem>
            )
          })}
        </StyledMenu>
      </Box>
      <Stack sx={{display: {xs: "none", md: "flex"}, flexDirection: "row", ...sx}}>
        {mainItems?.map((item) => {
          return (
            <CustomButton
              data={item}
              scope={"main-navigation"}
            />
          )
        })}
      </Stack>
  </>
    );
}

export default Navigation