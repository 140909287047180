import React, {ComponentProps, FC, ReactElement, useMemo} from 'react';
import {getClassName, KirbyButton, parseCss} from "@gk-lab/kirbyreact";
import {Button, Typography} from "@mui/material";
import {Link as RouterLink, useNavigate} from "react-router-dom";

interface CustomButtonProps extends ComponentProps<typeof KirbyButton> {

}

const CustomButton: FC<CustomButtonProps> = (props:CustomButtonProps): ReactElement => {
  const navigate = useNavigate();

  const className = useMemo(() => {
    const cl: string[] = props.className ? [props.className] : [];
    if (props.scope) {
      cl.push(props.scope);
    }

    if (props.data.align) {
      cl.push(props.data.align);
    }

    if (props.data.variant) {
      cl.push(props.data.variant);
    }

    return getClassName(props.data, props.scope, cl.join(" "));

  }, [props.className, props.scope, props.data.align, props.data.variant]);

  const to: string = useMemo(() => {
    let str = "/";
    if (props.data.to) {
      str = str + props.data.to;
    }
    if (props.data.anchor) {
      str = str + props.data.anchor;
    }
    return str;
  }, [props.data.to, props.data.anchor]);

  function onClick(e: any): void {
    if (props.data.anchor) {
      navigate((props.data.anchor ?? ''), {replace: false})
    }

    if (props.onClick) {
      props.onClick(e);
    }
  }

  if(props.data.variant === 'pdf') {
    const d = {
      ...props.data,
      variant: "text",
    }
    return (
      <KirbyButton data={d} className={"download"} scope={props.scope}>
        <img src={'img/pdf-icon.png'} alt={'pdf-icon'}/>
        <Typography component={'span'}
                    sx={{
                      marginLeft: "0.5rem"
                    }}
        >
          {props.data.text}
        </Typography>
      </KirbyButton>
    )
  }

  if (props.data.href) {
    return (
      <Button
        disableRipple
        //variant={variant}
        // component={RouterLink}
        className={className}
        href={props.data.href}
        target={"_blank"}
        sx={{
          ...(props.data.css && parseCss(props.data.css)),
          ...props.sx
        }}
        //onClick={onClick}
        key={`${props.data.text}`}
      >
        {props.data.text}
      </Button>
    );
  } else if (props.data.mailto) {
    return (
      <Button
        disableRipple
        //variant={variant}
        // component={RouterLink}
        className={className}
        href={`mailto:${props.data.mailto}`}
        sx={{
          ...(props.data.css && parseCss(props.data.css)),
          ...props.sx
        }}
        onClick={onClick}
        key={`${props.data.text}`}
      >
        {props.data.text}
      </Button>
    );
  }

  return (
    <Button
      disableRipple
      //variant={variant}
      component={RouterLink}
      className={className}
      to={to}
      sx={{
        ...(props.data.css && parseCss(props.data.css)),
        ...props.sx
      }}
      onClick={onClick}
      key={`${props.data.to}-${props.data.text}`}
    >
      {props.data.text}
    </Button>
  );

}

export default CustomButton