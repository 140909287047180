import React, {FC, ReactElement, useMemo} from 'react';
import {KirbyProps} from "@gk-lab/kirbyreact";

export enum DividerSize {
  SMALL='small',
  LARGE='large'
}
interface LineDividerProps extends KirbyProps {
  width:number;
  size?:DividerSize | string
}
// fill:none;stroke:#C12525;stroke-width:0.5;stroke-linecap:round;
const LineDivider: FC<LineDividerProps> = ({width, size}): ReactElement => {

  const height = useMemo(() => {
    switch (size) {
      case DividerSize.LARGE:
        return 24
      case DividerSize.SMALL:
      default:
        return 14
    }
  },[size])

  const distance = useMemo(() => {
    switch (size) {
      case DividerSize.LARGE:
        return 34
      case DividerSize.SMALL:
      default:
        return 20
    }
  },[size])

  const stroke = useMemo(() => {
    switch (size) {
      case DividerSize.LARGE:
        return 2
      case DividerSize.SMALL:
      default:
        return 1
    }
  },[size])

  const realWidth = useMemo(() => {
    return Math.ceil(width / distance) * distance
  }, [width, distance])

  const lines = useMemo(() => {
    const l = []
    for (let i=0; i<realWidth; i+=distance) {
      const x1 = 0 + i
      const x2 = x1 + distance - stroke
      const y1 = height;
      const y2 = 0;

      l.push(
        <line
          fill={'none'}
          stroke={'#C12525'}
          strokeWidth={stroke}
          strokeLinecap={'round'}
          x1={`${x1.toString()}`}
          y1={`${y1.toString()}`}
          x2={`${x2.toString()}`}
          y2={`${y2.toString()}`}
        />
      )
    }
    return l;
  }, [realWidth, height, distance, stroke]);


  return (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={realWidth}
        height={height}
        viewBox={`0 0 ${realWidth} ${height}`}
      >
        <g>
          {lines}
        </g>
      </svg>
    );
}

export default LineDivider