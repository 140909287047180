import React, { useMemo } from "react";
import { Stack } from "@mui/material";
import {BlockSplitGroup} from "../types/Block";
import {getClassName, KirbyProps, parseCss} from "@gk-lab/kirbyreact";

interface Props extends KirbyProps {
  data: BlockSplitGroup;
  content:{
    col1?:(React.JSX.Element | null)[] | React.JSX.Element | null,
    col2?:(React.JSX.Element | null)[] | React.JSX.Element | null,
  }
}

export default function SplitGroup(props: Props) {

  const col1Width = useMemo(() => {
    const b = props.data.breakpoint ?? "xs";
    const w = props.data.width.col1 ?? 100;
    const u = props.data.width.unit ?? "%";
    const v = `${w}${u}`;

    if(b === "xs") return v

    return {
      xs: "100%",
      [b]: v
    }
  }, [props.data]);

  const col2Width = useMemo(() => {
    const b = props.data.breakpoint ?? "xs";
    const w = props.data.width.col2 ?? 100;
    const u = props.data.width.unit ?? "%";
    const v = `${w}${u}`;

    if(b === "xs") return v

    return {
      xs: "100%",
      [b]: v
    }
  }, [props.data]);

  const fd = useMemo(() => {
    const b = props.data.breakpoint ?? "xs";
      if(props.data.direction && b !== "xs") {
        return {
          xs: props.data.direction ?? "row",
          [b]: "row"
        }
      }
      return "row"
  }, [props.data.direction, props.data.breakpoint])

  const sx = useMemo(() => {
    return {
    ...props.sx,
    ...(props.data.css ? parseCss(props.data.css) : {}),
    }
  }, [props.sx, props.data.css]);

  return (
    <Stack
      sx={{
        width: "100%",
        flexDirection: fd,
        flexWrap: "wrap",
        ...sx
      }}
      className={getClassName(
        props.data,
        `splitgroup`,
        props.className
      )}
      id={props.data?.id}
    >
      <Stack
        flexDirection={"column"}
        sx={{
          width: col1Width
        }}
        className={'col1'}
      >
        {props.content.col1}
      </Stack>
      <Stack
        flexDirection={"column"}
        sx={{
          width: col2Width
        }}
        className={'col2'}
      >
        {props.content.col2}
      </Stack>
    </Stack>
  );
}
