import React, {FC, ReactElement, useMemo} from 'react';
import {getClassName, KirbyProps, ResponsiveSx} from "@gk-lab/kirbyreact";
import {BlockSimpleCard, CustomBlockType} from "../types/Block";
import {Box, Stack} from "@mui/material";
import {cssWrapper, getResponsiveValues} from "../utils/utils";
import Divider from "./Divider";
import {white} from "../Theme";

interface SimpleCardProps extends KirbyProps {
  data: BlockSimpleCard,
  center?: (React.JSX.Element | null)[]
  bottom?: (React.JSX.Element | null)[]
}

const SimpleCard: FC<SimpleCardProps> = ({data, center, bottom}): ReactElement => {

  const width = useMemo(() => {
    if (data.css?.width) {
      return getResponsiveValues(data.css.width as ResponsiveSx)
    } else {
      return {xs: "100%"}
    }
  }, [data.css])

  const edgeWidthXs = 80
  const edgeWidthLg = 140

  const bg = useMemo(() => {
    if (data.uistyle?.backgroundcolor && data.uistyle?.backgroundcolor !== 'none') {
      return data.uistyle?.backgroundcolor;
    }
    return "white"
  }, [data.uistyle?.backgroundcolor])
  return (
    cssWrapper(<Box
        className={getClassName(data, "SimpleCard")}
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "transparent",
          height: 0,
          width,
          paddingBottom: width,
          overflow: "hidden",
          // aspectRatio: "1 / 1"
          position: "relative",
        }}
      >
        <Stack
          className="card-content"
          sx={{
            position: "absolute",
            width: "100%",
            height: {
              xs: `calc( 100% - ${edgeWidthXs}px )`,
              lg: `calc( 100% - ${edgeWidthLg}px )`,
            },
            backgroundColor: white,
            justifyContent: "center",
            paddingTop: {
              xs: `${edgeWidthXs*0.25}px`,
              lg: `${edgeWidthLg*0.25}px`,
            },
          }}
        >
          {center}
        </Stack>
        <Stack
          className="card-bottom"
          sx={{
            position: "absolute",
            bottom: "1px",
            width: "100%",
            display: "flex",
            flexGrow: 0,
            height: "0px",
            borderTop: {
              xs: `${edgeWidthXs}px solid ${bg}`,
              lg: `${edgeWidthLg}px solid ${bg}`
            },
            borderRight: {
              xs: `${edgeWidthXs}px solid transparent`,
              lg: `${edgeWidthLg}px solid transparent`
            },
            backgroundColor: "transparent",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              marginTop: {
                xs: `-${edgeWidthXs}px`,
                lg: `-${edgeWidthLg}px`,
              },
            }}
          >
            {data.divider &&
              <Divider
                data={{
                  type: CustomBlockType.DIVIDER,
                  width: "80%",
                }}
                sx={{
                  paddingTop: {
                    xs: 0,
                    lg: "1rem"
                  },
                  paddingBottom: {
                    xs: "1rem",
                    lg: "1rem"
                  },
                }}
              />}
            {bottom}
          </Box>

        </Stack>
      </Box>,
      data.css,
      'card'
    )
  )
}

export default SimpleCard


