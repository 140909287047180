import React, {useEffect, useState} from "react";
import {
  AppStatus,
  KirbyRouter,
  ScrollToTop,
  useAppState,
  useKirbyData,
} from "@gk-lab/kirbyreact";
import { Box, Container, Fab, Typography } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { getSectionElement } from "./utils/sectionUtils";
import { useLocation, useNavigationType } from "react-router-dom";
import {accent, appWidthPx, headerHeight, primary, white} from "./Theme";
import { getPath } from "./config";
import AppHeader from "./components/AppHeader";
import Navigation from "./components/Navigation";
import {scrollBodyToElement} from "./utils/utils";
function App() {
  const [initialized, setInitialized] = useState<boolean>(false)
  const { listedSections, state, /*sticky, *//*hidemainnavigation, */ header } = useAppState();
  // eslint-disable-next-line
  const kirbyData = useKirbyData(getPath());
  // const [showDisclaimer, setShowDisclaimer] = useState(false);

  const [open, setOpen] = React.useState(false);

  const { pathname, hash } = useLocation();
  const navigationType = useNavigationType();

  // mount
  useEffect(() => {
    if(!kirbyData.isLoading && !initialized) {
      setTimeout(
        () => {
          if(!scrollBodyToElement(hash.replace("#", "").toLowerCase(), true, -74)) {
            window.scrollTo({ top: 0 } )
          }
        },100
      )
      setInitialized(true)
    }
  }, [initialized, setInitialized, kirbyData.isLoading]);

  // TODO Scroll überprüfen
  useEffect(() => {
    if(initialized) {
      if(!scrollBodyToElement(hash.replace("#", "").toLowerCase(), true, -74)) {
        window.scrollTo({ top: 0 } )
      }
    }
  }, [navigationType, pathname, hash, initialized]);

  return (
    <Box>
      {state === AppStatus.LOADED && listedSections && (
        <>
          <Box component={"main"} sx={{ paddingTop: headerHeight }}>
            <Container maxWidth={false}>
              <div id={"back-to-top-anchor"}></div>
              <KirbyRouter
                sections={listedSections}
                getSectionElement={getSectionElement}
              />
            </Container>
          </Box>
          <AppHeader
            sx={{
              gridArea: "header",
              maxHeight: headerHeight,
              background: white,
            }}
            data={header}
            maxWidth={false}
            containerSx={{
              maxWidth: "1920px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <Navigation
              mainItems={kirbyData.data?.navitems}
              metaItems={kirbyData.data?.metaitems}
              sx={{
                margin: "auto 1rem",
              }}
            />
            <Container
              maxWidth={false}
              sx={{
                maxWidth: appWidthPx,
                position: "fixed",
                bottom: 0,
              }}
            >
              {!open && (
                <ScrollToTop
                  boxSx={{
                    position: "absolute",
                    bottom: "1rem",
                    right: { xs: "1rem", md: "2rem" },
                    left: "unset",
                  }}
                >
                  <Fab
                    color="secondary"
                    size="small"
                    aria-label="scroll back to top"
                    sx={{
                      backgroundColor: accent,
                      borderRadius: 0,
                      "&:hover, &:focus": {
                        backgroundColor: primary,
                        color: white,
                      },
                    }}
                  >
                    <KeyboardArrowUpIcon />
                  </Fab>
                </ScrollToTop>
              )}
            </Container>
          </AppHeader>
          {/*<KirbySnackbar
                data={{type: BlockType.SNACKBAR, text: "Disclaimer"}}
                handleClose={() => setShowDisclaimer(false)}
                open={showDisclaimer}
                muiProps={{
                  anchorOrigin: { vertical: "bottom", horizontal: "left" }
                }}
              />*/}
        </>
      )}
      {state !== AppStatus.LOADED && (
        <Box p={4}>
          <Typography component={"p"} fontSize={"1rem"}>
            Einen Moment bitte...
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default App;
