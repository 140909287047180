import {SectionCase, SectionCases, SectionDefault} from "../types/Section";
import {
  Block,
  BlockType,
  getKirbyBlockElement,
  IKirbySection,
  KirbyBlockGroup,
  KirbySlide,
  KirbySlideshow,
} from "@gk-lab/kirbyreact";
import React, {ReactNode} from "react";
import {Container, Grid, Stack} from "@mui/material";
import {CustomBlock, CustomBlockType} from "../types/Block";
import {slideshowButtonSx} from "../Theme";
import DefaultPage from "../pages/DefaultPage";
import PageContainer from "../components/PageContainer";
import KirbyForm from "../components/KirbyForm/KirbyForm";
import CookieReset from "../components/CookieReset";
import CustomFooter from "../components/CustomFooter";
import SimpleCard from "../components/SimpleCard";
import CustomButton from "../components/CustomButton";
import Divider from "../components/Divider";
import SplitGroup from "../components/SplitGroup";

export type IDMSSection =
  | SectionCase
  | SectionCases
  | SectionDefault
  | IKirbySection;

export function wrapWithCustomRow (element:ReactNode | null, type?:BlockType | "", idx?:number):React.JSX.Element | ReactNode | null {
  const key = idx || type ? `${type ?? "block"}-${idx ? idx.toString() : "0"}` : ""

  switch (type) {
    case BlockType.PAGECONTAINER:
    case BlockType.FRAME:
    case BlockType.PAGE:
      return (
        <Container
          maxWidth={false}
          sx={{
            maxWidth: "1920px",
          }}
          key={key}
        >
          <Stack direction={"column"} className={"section-stack"}>
            {element}
          </Stack>
        </Container>
      )
    case BlockType.NAVIGATION:
      return (
        <Container
          maxWidth={false}
          sx={{
            marginTop: "6rem",
            marginBottom: "4rem",
            display: "flex",
            maxWidth: "1920px",
            height: "100%",
            justifyContent: "flex-end",
            alignItems: "center",
            overflowX: "hidden",
            maxHeight: "calc(100% - 6rem)",
          }}
          key={key}
        >
          {element}
        </Container>
      )

    default:
      return element;
  }
}

/**
 * Get an route-template by section.id
 * @param section
 * @param parentUrl
 * @param inline
 */
export function getSectionElement (section:IKirbySection | IDMSSection, parentUrl:string, inline:boolean) {
  switch (section.id) {
    default:
      return (
        <DefaultPage
          key={section.url}
          data={section as SectionDefault}
          inline={false}
          parentUrl={parentUrl}
        />
    )
  }
}

export function getBlockElement (b:Block | CustomBlock, idx:number, scope?:string):React.JSX.Element | null {
  if (b) {
    switch (b.type) {
      case BlockType.BUTTON:
      case BlockType.LINK:
        return <CustomButton key={`button-${idx.toString()}`} data={b}  scope={scope} />
      case CustomBlockType.DIVIDER:
        return <Divider data={b} key={`Divider-${idx.toString()}`} />
      case CustomBlockType.SPLITGROUP:
        return <SplitGroup
          key={`Splitgroup-${idx.toString()}`}
          data={b}
          content={{
            col1: b.col1 ? b.col1.map((block, idx) => {
                return getBlockElement(block, idx);
              }) : [],
            col2: b.col2 ? b.col2.map((block, idx) => {
                return getBlockElement(block, idx);
              }) : [],
          }}
        />
      case CustomBlockType.SIMPLECARD:
        return (
          <SimpleCard
            data={b} key={`SimpleCard-${idx.toString()}`}
            center={b.center && b.center.map((block, idx) => {
              return getBlockElement(block, idx, 'card-center');
            })}
            bottom={b.bottom && b.bottom.map((block, idx) => {
              return getBlockElement(block, idx, 'card-bottom');
            })}
          />
        )
      case BlockType.SLIDESHOW2:
        return (
          <KirbySlideshow
            data={b} key={`slideshow-${idx.toString()}`}
            carouselProps={{
              buttonSx: slideshowButtonSx,
              indicatorPos: "right",
              navigationPos: "spread",
            }}
          >
            {b.slides && b.slides.map((slide, idx) => {
              return getBlockElement(slide, idx);
            })}
          </KirbySlideshow>
        );
      case BlockType.SLIDE:
        return (
          <KirbySlide key={`slide-${idx.toString()}`} data={b}>
            {b.blocks && b.blocks.map((block, idx) => {
              return getBlockElement(block, idx, "slide");
            })}
          </KirbySlide>
        );
      case BlockType.BLOCKGROUP:
        const elements = b.items?.map((bc:Block) => {
          return getBlockElement(bc, 0, scope)
        });
        return <KirbyBlockGroup key={`group-${idx.toString()}`} data={b} idx={idx+1} elements={elements}/>
      case BlockType.PAGECONTAINER:
        return <PageContainer key={`page-${idx.toString()}`} data={b} getBlocks={getBlockElement} />
      case CustomBlockType.FORM:
        return <KirbyForm key='anchor-form-${idx.toString()' data={b} />
      case CustomBlockType.COOKIERESET:
        return <CookieReset key={`cookiereset-${idx.toString()}`} data={b} scope={scope} />
      case BlockType.FOOTER:
        return <CustomFooter key={`footer-${idx.toString()}`} getBlockElement={getBlockElement} />
      default:
        return getKirbyBlockElement(b, idx, scope);
    }
  }
  return null;
}

export function wrapWithRow (element:ReactNode, type?:BlockType | "", idx?:number):React.JSX.Element {
  const key = idx || type ? `${type ?? "block"}-${idx ? idx.toString() : "0"}` : ""

  switch (type) {
    case BlockType.ANIMATIONS:
    case BlockType.ANIMATION:
    case BlockType.SLIDER:
    case BlockType.PAGECONTAINER:
    case BlockType.FRAME:
      return (
        <Container maxWidth={"xl"} key={key}>
      <Grid container>
      <Grid item xs={12}>
      {element}
      </Grid>
      </Grid>
      </Container>
    )
    default:
      return (
        <Container maxWidth={"xl"} key={key}>
      <Grid container rowSpacing={4} justifyContent={"center"}>
      <Grid item xs={0} lg={2} xl={2} />
    <Grid item xs={12} lg={8} xl={8}>
      {element}
      </Grid>
      <Grid item xs={0} lg={2} xl={2} />
    </Grid>
    </Container>
    )
  }
}