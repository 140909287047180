import {
  Block,
  FlexAlign,
  IKirbyBlock,
  ResponsiveSx,
} from "@gk-lab/kirbyreact";
import {DividerSize} from "../icons/LineDivider";

export enum CustomBlockType {
  FORM = "form",
  COOKIERESET = "cookiereset",
  STICKY = "sticky",
  SIMPLECARD = "card",
  DIVIDER = "divider",
  SPLITGROUP = "splitgroup",
}

export type SocialLink = {
  name: string;
  url: string;
  icon: string;
};


export interface BlockCookieReset extends IKirbyBlock {
  type: CustomBlockType.COOKIERESET;
  text: string;
  variant?: string;
  align?: FlexAlign;
}

export interface BlockForm extends IKirbyBlock {
  type: CustomBlockType.FORM;
  cta: string;
  action: string;
  disclaimerCopy: string;
  successCopy?: string;
  errorCopy?: string;
  infos: Block[];
}

export interface BlockSticky extends IKirbyBlock {
  type: CustomBlockType.STICKY;
  src?: string;
  alt?: string;
  to?: string;
  padding?: {
    [key: string]: ResponsiveSx;
  };
}
export interface BlockSimpleCard extends IKirbyBlock {
  type: CustomBlockType.SIMPLECARD;
  center?: Block[];
  divider?:boolean
  bottom: Block[];
}

export interface BlockSplitGroup extends IKirbyBlock {
  type: CustomBlockType.SPLITGROUP;
  col1?: Block[];
  col2?: Block[];
  width:{col1:number, col2:number, unit:string};
  breakpoint:"xs"|"sm"|"md"|"lg"
  direction?:"row"|"row-reverse"
}

export interface BlockDivider extends IKirbyBlock {
  type: CustomBlockType.DIVIDER;
  size?: DividerSize
  width?: string
}

export interface BlockForm extends IKirbyBlock {
  type: CustomBlockType.FORM;
  cta: string;
  action: string;
  disclaimerCopy: string;
  successCopy?: string;
  errorCopy?: string;
  infos: Block[];
}

export type CustomBlock =
  | BlockForm
  | BlockCookieReset
  | BlockSimpleCard
  | BlockDivider
  | BlockSplitGroup
