import React, { ReactNode } from "react";
import { SectionDefault } from "../types/Section";
import { Box } from "@mui/material";
import { SxProps } from "@mui/system";
import {BlockType, KirbyProps, useAppState} from "@gk-lab/kirbyreact";
import { getBlockElement, wrapWithCustomRow } from "../utils/sectionUtils";
import { CustomScope } from "../types/Scope";
import CustomFooter from "../components/CustomFooter";

interface Props extends KirbyProps {
  data: SectionDefault;
}

function getSection(
  id: string | undefined,
  children: ReactNode,
  sx?: SxProps<any> | undefined,
  inline?: boolean,
) {
  return (
    <Box
      component={"section"}
      display={"flex"}
      flexDirection={"column"}
      sx={{
        padding: "0",
        minHeight: inline ? "unset" : "calc( 100vh - 110px)",
        ...sx,
      }}
      id={id}
    >
      {children}
    </Box>
  );
}

export default function DefaultPage(props: Props) {
  const { data } = props;
  const { blocks, stage } = data;

  return getSection(
    data?.url ?? undefined,
    <Box>
      {stage &&
        stage.map((block, idx) => {
          const component = getBlockElement(block, idx, CustomScope.MAIN);
          return wrapWithCustomRow(component, BlockType.PAGE, idx + 1);
        })}
      {blocks &&
        blocks.map((block, idx) => {
          const component = getBlockElement(block, idx, CustomScope.MAIN);
          return wrapWithCustomRow(component, BlockType.PAGE, idx + 1);
        })}
      {/*<JSONViewer data={data} name={"Cases"} open />*/}
      {data.showfooter && <CustomFooter getBlockElement={getBlockElement} />}
    </Box>,
    props.sx,
    props.inline,
  );
}
