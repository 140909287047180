import {Breakpoints, CSSObject} from "@mui/material";

export const h1 = (breakpoints:Breakpoints):CSSObject => ({
  textTransform: "uppercase",
  fontSize: "1rem",
  fontWeight: 500,
  lineHeight: 0.98,
  margin: "0 1rem",
  position: "relative",
  [breakpoints.up("sm")]: {

  },
  [breakpoints.up("md")]: {

  },
  [breakpoints.up("lg")]: {

  },
  [breakpoints.up("xl")]: {

  },
})

export const h2 = (breakpoints:Breakpoints):CSSObject => ({
  textTransform: "uppercase",
  fontSize: "1.75rem",
  fontWeight: 700,
  lineHeight: 1.2,
  marginBottom: "0.5rem",
  position: "relative",
  [breakpoints.up("md")]: {
    fontSize: "2.5rem"
  },
  [breakpoints.up("sm")]: {
    fontSize: "3rem"
  },
  [breakpoints.up("lg")]: {
    fontSize: "3rem"
  },
  [breakpoints.up("xl")]: {
    fontSize: "4rem"
  },
})

export const h3 = (breakpoints:Breakpoints):CSSObject => ({
  fontSize: "1.75rem",
  fontWeight: 700,
  lineHeight: 1,
  textTransform: "uppercase",
  position: "relative",
  // marginBottom: "2rem",
  [breakpoints.up("sm")]: {
    fontSize: "2rem",
  },
  [breakpoints.up("md")]: {
    //fontSize: "3rem",
  },
  [breakpoints.up("lg")]: {
    lineHeight: 1.125,
    fontSize: "3.25rem",
  },
  [breakpoints.up("xl")]: {

  },
})

export const h4 = (breakpoints:Breakpoints):CSSObject => ({
  fontSize: "2rem",
  fontWeight: 700,
  marginBottom: "0.25rem",
  textTransform: "uppercase",
  position: "relative",
  [breakpoints.up("sm")]: {
    fontSize: "2.25rem",
  },
  [breakpoints.up("md")]: {
    fontSize: "2.5rem",
  },
  [breakpoints.up("lg")]: {
    fontSize: "2.75rem",
  },
  [breakpoints.up("xl")]: {
    fontSize: "3rem",
  },
})

export const h5 = (breakpoints:Breakpoints):CSSObject => ({
  fontSize: "1.5rem",
  fontWeight: 700,
  margin: 0,
  lineHeight: "1.8",
  marginBottom: "0",
  position: "relative",
  [breakpoints.up("lg")]: {
    fontSize: "1.5rem",
  },
})

export const h6 = (breakpoints:Breakpoints):CSSObject => ({
  fontSize: "1rem",
  transform: "none",
  fontWeight: 300,
  marginBottom: "0.25rem",
  marginTop: "0.25rem",
  [breakpoints.up("lg")]: {
    fontSize: "1.25rem",
  },
})
