import React, { useMemo } from "react";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import {
  AppBar,
  Toolbar,
  Box,
  Stack,
  Container,
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {BlockHeader, KirbyProps} from "@gk-lab/kirbyreact";

interface Props extends KirbyProps {
  data?: BlockHeader;
  /**
   * Added to <AppBar />
   */
  sx?: SxProps<Theme>;
  /**
   * <Container maxWidth />
   */
  maxWidth: "sm" | "md" | "lg" | "xl" | false;
  /**
   * Added to <Container />
   */
  containerSx?: SxProps<Theme>;
}

export default function AppHeader(props: Props) {

  const navigate = useNavigate();

  const toolbarStyle: SxProps = {
    maxHeight: "inherit",
  };

  const containerMaxWidth: "sm" | "md" | "lg" | "xl" | false = useMemo(() => {
    switch (props.maxWidth) {
      case "sm":
      case "md":
      case "lg":
      case "xl":
        return props.maxWidth;
      default:
        return false;
    }
  }, [props.maxWidth]);

  return (
    <AppBar
      position="fixed"
      elevation={props.asOverlay ? 0 : 3}
      sx={{ ...props.sx }}
    >
      <Container
        maxWidth={containerMaxWidth}
        sx={{ maxHeight: "inherit", ...props.containerSx }}
      >
        <Toolbar
          sx={toolbarStyle}
          variant={props.asOverlay ? "dense" : "regular"}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ width: "100%", maxHeight: "inherit" }}
          >
            <Stack
              direction={"row"}
              alignSelf={"flex-start"}
              alignItems={"center"}
              sx={{ maxHeight: "inherit" }}
            >
              {props.data?.logourl && (
                <Box
                  className={"header"}
                  sx={{
                    alignSelf: "flex-start",
                    maxWidth: { xs: "200px", md: "unset" },
                  }}
                  onClick={() => navigate('home#home', {replace: true})}
                >
                  <img
                    src={props.data?.logourl}
                    alt={props.data?.title}
                    width={"100%"}
                  />
                </Box>
              )}
              {/*props.data?.title && !props.asOverlay && (
                <Typography variant="h1" noWrap>
                  {props.data?.title}
                </Typography>
              )*/}
            </Stack>
          </Stack>
        </Toolbar>
        {props.children}
      </Container>
    </AppBar>
  );
}
