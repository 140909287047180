import React from "react";
import { Box, Container, Stack } from "@mui/material";
import { Block, useAppState } from "@gk-lab/kirbyreact";
import { footerBackground } from "../Theme";
import { SxProps, Theme } from "@mui/system";
import {CustomScope} from "../types/Scope";

interface Props {
  getBlockElement(
    b: Block,
    idx: number,
    scope?: string,
  ): React.JSX.Element | null;
  sx?: SxProps<Theme>;
}

export default function CustomFooter(props: Props) {
  const { footer } = useAppState();

  const {
    left,
    right,
  } = footer;

  return (
    <Box
      component={"footer"}
      sx={{
        width: "100%",
        zIndex: 1,
        ...props.sx,
      }}
    >
      <Container
        maxWidth={false}
        sx={{
          maxWidth: "1920px",
          backgroundColor: footerBackground,
          paddingBottom: "4rem"
        }}
      >
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent={"space-between"}
          rowGap={{ xs: 4, lg: 0 }}
          sx={{
            width: "100%",
            padding: { xs: "1rem" },
          }}
        >
          <Stack direction={"row"} width={{ xs: "100%", lg: `${100 / 2}%` }}>
            {left?.map((b: Block, idx: number) => {
              return props.getBlockElement(b, idx, CustomScope.FOOTER);
            })}
          </Stack>
          <Stack
            direction={"row"}
            width={{ xs: "100%", lg: `${100 / 2}%` }}
            justifyContent={"flex-end"}
          >
            {right?.map((b: Block, idx: number) => {
              return props.getBlockElement(b, idx, CustomScope.FOOTER);
            })}
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}
