import {lightGrey, secondaryDark} from "../Theme";
import {Breakpoints, CSSObject} from "@mui/material";

export const defaultStyles = (breakpoints:Breakpoints):CSSObject => ({
  "&.bg-grey": {
    backgroundColor: `${lightGrey} !important`,
  },
  "&.bg-darkgrey": {
    backgroundColor: `${secondaryDark} !important`,
  },

  "&.mt-medium": {
    marginTop: "0.5rem !important",
    [breakpoints.up("md")]: {
      marginTop: "2rem !important",
    },
  },
  "&.mt-large": {
    marginTop: "2rem !important",
    [breakpoints.up("md")]: {
      marginTop: "4rem !important",
    },
  },

  "&.mb-medium": {
    marginBottom: "1rem !important",
    [breakpoints.up("md")]: {
      marginBottom: "2rem !important",
    },
  },
  "&.mb-large": {
    marginBottom: "2rem !important",
    [breakpoints.up("md")]: {
      marginBottom: "4rem !important",
    },
  },
  "&.mx-medium": {
    marginLeft: "1rem !important",
    marginRight: "1rem !important",
    [breakpoints.up("md")]: {
      marginLeft: "1rem !important",
      marginRight: "1rem !important",
    },
    [breakpoints.up("lg")]: {
      marginLeft: "2rem !important",
      marginRight: "2rem !important",
    },
    [breakpoints.up("xl")]: {
      marginLeft: "4rem !important",
      marginRight: "4rem !important",
    },
  },
  "&.mx-large": {
    marginLeft: "2rem !important",
    marginRight: "2rem !important",

    [breakpoints.up("md")]: {
      marginLeft: "4rem !important",
      marginRight: "4rem !important",
    },
  },
  "&.pt-medium": {
    paddingTop: "2rem",
    [breakpoints.up("md")]: {
      paddingTop: "4rem",
    },
    [breakpoints.up("xl")]: {
      paddingTop: "6rem",
    },
  },
  "&.pt-large": {
    paddingTop: "4rem",
    [breakpoints.up("md")]: {
      paddingTop: "6rem",
    },
    [breakpoints.up("lg")]: {
      paddingTop: "8rem",
    },
  },
  "&.pb-medium": {
    paddingBottom: "1rem",
    [breakpoints.up("md")]: {
      paddingBottom: "2rem",
    },
  },
  "&.pb-large": {
    paddingBottom: "2rem",
    [breakpoints.up("md")]: {
      paddingBottom: "4rem",
    },
    [breakpoints.up("lg")]: {
      paddingBottom: "6rem",
    },
  },
  "&.px-medium": {
    paddingLeft: "1rem",
    paddingRight: "1rem",
    [breakpoints.up("sm")]: {
      paddingLeft: "2rem",
      paddingRight: "2rem",
    },
    [breakpoints.up("lg")]: {
      paddingLeft: "3rem",
      paddingRight: "3rem",
    },
    [breakpoints.up("xl")]: {
      paddingLeft: "4rem",
      paddingRight: "4rem",
    },
  },
  "&.px-large": {
    paddingLeft: "1rem",
    paddingRight: "1rem",
    [breakpoints.up("sm")]: {
      paddingLeft: "2rem",
      paddingRight: "2rem",
    },
    [breakpoints.up("lg")]: {
      paddingLeft: "3rem",
      paddingRight: "3rem",
    },
    [breakpoints.up("lg")]: {
      paddingLeft: "6rem",
      paddingRight: "6rem",
    },
    [breakpoints.up("xl")]: {
      paddingLeft: "10rem",
      paddingRight: "10rem",
    },
  },
});