import {getClassName, KirbyProps, parseCss} from '@gk-lab/kirbyreact';
import React, {FC, ReactElement} from 'react';
import {Box, Stack} from "@mui/material";
import LineDivider from "../icons/LineDivider";
import {BlockDivider} from "../types/Block";
import {appWidth} from "../Theme";

interface DividerProps extends KirbyProps {
  data:BlockDivider
}

const Divider: FC<DividerProps> = (props): ReactElement => {
    return (
      <Box
        className={getClassName(props.data, "divider", "divider")}
        sx={{
          pointerEvents: 'none',
          ...(props.data.css && parseCss(props.data.css)),
          ...props.sx
        }}
      >
        <Stack
          className={'mask'}
          sx={{
            width: {
              xs: "80%",
              lg: props.data.width ?? '100%'
            },
            overflowX: 'hidden',
            alignItems: 'flex-end'
          }}
        >
          <LineDivider width={props.data.size === 'small' ? appWidth/2 : appWidth} size={props.data.size ?? 'small'} />
        </Stack>
      </Box>
    );
}

export default Divider